import {
    Button,
    Colors,
    Divider,
    Icon,
    Intent,
    Menu,
    MenuDivider,
    MenuItem,
    ProgressBar,
    Tag,
} from "@blueprintjs/core";
import { Popover2, Tooltip2 } from "@blueprintjs/popover2";
import { Cell, Column, Table2, TableLoadingOption } from "@blueprintjs/table";
import { RenderMode } from "@blueprintjs/table/lib/esnext";
import PropTypes from "prop-types";
import React, { useCallback, useContext, useState } from "react";
import { useHistory } from "react-router-dom";
import styled from "styled-components";

import { formatCurrency, formatDateString } from "../../common/i18n";
import { buildAppUrl, formatPercent } from "../../common/utils";
import { DialogContext } from "../bp-dialog-context";
import { sortableColumnRenderer } from "../common/bp-table-helper";
import PlatformInfoBadge from "../common/platform-info-badge";
import UserBadge from "../common/user-badge";
import DialogAddMoneyFromWallet from "../dialogs/add-money-from-wallet";
import CreateTicket from "../dialogs/create-ticket";
import DeleteMessage from "../dialogs/delete-message";
import DialogRefundRemainingAmount from "../dialogs/refund_remaining_amount";
import DialogRestoreMoneyToWallet from "../dialogs/restore-money-from-message";
import PropertiesGrid from "../layout/properties-grid";

const StyledImage = styled.img`
    object-fit: scale-down;
    height: 55px;
    width: 55px;
`;

// BEGIN Cell menu
const handleShowMessage = (message) => {
    window.open(buildAppUrl(`/edit/stats/${message.id}`), message.id);
};
const handleEditMessagePage = (message) => {
    window.open(buildAppUrl(`/edit/message-editor/${message.id}`), message.id);
};

const canAddMoneyFromWallet = (message, permission) => {
    return message.state !== "completed" && message.availableWallet && permission;
};
const canRestoreMoney = (message, permission) => {
    return message.state === "completed" && message.stats.totalAmount - message.stats.totalSpent > 0 && permission;
};

const renderCellMenu = (
    message,
    {
        addMoneyFromWalletForMessage,
        restoreMoneyToWalletForMessage,
        refundRemainingAmount,
        deleteMessage,
        createTicket,
        history,
        hasPermission,
    }
) => {
    return (
        <Cell style={{ padding: 5 }}>
            <Popover2
                content={
                    <Menu>
                        <MenuDivider title="Actions" />
                        <MenuItem
                            icon="timeline-bar-chart"
                            onClick={() =>
                                history.push({
                                    pathname: `/messages/${message.id}/insights`,
                                    search: history.location.search,
                                })
                            }
                            text="Show insights"
                            label={<Icon icon="arrow-right" />}
                        />
                        <MenuItem
                            icon="properties"
                            onClick={() =>
                                history.push({
                                    pathname: `/messages/${message.id}/logs`,
                                    search: history.location.search,
                                })
                            }
                            text="Show logs"
                            label={<Icon icon="arrow-right" />}
                        />
                        <MenuItem
                            icon="search-around"
                            onClick={() =>
                                history.push({
                                    pathname: `/messages/${message.id}/platforms`,
                                    search: history.location.search,
                                })
                            }
                            text="Show platforms"
                            label={<Icon icon="arrow-right" />}
                        />
                        <Divider />
                        <MenuItem
                            disabled={message.state === "draft"}
                            icon={<Icon icon="timeline-area-chart" />}
                            onClick={() => handleShowMessage(message)}
                            text="Show message stats "
                        />
                        <MenuItem
                            icon={<Icon icon="layers" />}
                            onClick={() =>
                                window.open(
                                    buildAppUrl(`/edit/ads/?q=${message?.title.substring(0, 15)}&s=${message?.state}`),
                                    message.id
                                )
                            }
                            text="Show message on list "
                        />
                        <MenuItem
                            icon={<Icon icon="edit" />}
                            intent={Intent.PRIMARY}
                            onClick={() =>
                                history.push({
                                    pathname: `/messages/${message.id}/edit/`,
                                    search: history.location.search,
                                })
                            }
                            text="Edit on Staff"
                        />
                        <MenuItem
                            disabled={message.state === "completed"}
                            intent={Intent.WARNING}
                            icon={<Icon icon="manually-entered-data" />}
                            onClick={() => handleEditMessagePage(message)}
                            text="Edit on Amplify App"
                        />
                        <MenuItem
                            disabled={message.state !== "draft"}
                            intent={Intent.DANGER}
                            icon={<Icon icon="trash" />}
                            onClick={async () => {
                                await deleteMessage(message);
                            }}
                            text="Delete"
                        />
                        <Divider />
                        <MenuItem
                            disabled={message.state === "completed"}
                            icon={<Icon icon="label" />}
                            onClick={async () => {
                                await createTicket(message);
                            }}
                            text="Create a Ticket"
                        />
                        <MenuItem
                            icon="trending-up"
                            onClick={async () => {
                                await addMoneyFromWalletForMessage(message);
                            }}
                            text="Add money from wallet (BOOST !)"
                            disabled={
                                !canAddMoneyFromWallet(message, hasPermission && hasPermission("staff", "write_wallet"))
                            }
                        />
                        <MenuItem
                            icon="bank-account"
                            onClick={async () => {
                                await restoreMoneyToWalletForMessage(message);
                            }}
                            text="Restore money to wallet"
                            disabled={
                                !canRestoreMoney(message, hasPermission && hasPermission("staff", "write_wallet"))
                            }
                        />
                        <Divider />
                        <MenuItem
                            icon="bank-account"
                            onClick={async () => {
                                await refundRemainingAmount(message);
                            }}
                            text="Refund to credit card"
                            disabled={!canRestoreMoney(message)}
                        />
                        <Divider />
                        <MenuItem
                            icon="barcode"
                            text="Copy ID to clipboard"
                            onClick={() => {
                                navigator.clipboard.writeText(message.id);
                            }}
                            label={<Icon icon="clipboard" />}
                        />
                    </Menu>
                }
            >
                <Button large minimal icon="menu" />
            </Popover2>
        </Cell>
    );
};

// END Cell menu

// BEGIN CELL image + menu
const renderCellAgency = (message) => {
    if (message.agency)
        return (
            <Tag minimal intent={Intent.NONE}>
                {message.agency?.name}
            </Tag>
        );
    return (
        <Tag minimal intent={Intent.SUCCESS}>
            Amplify SA
        </Tag>
    );
};
const renderCellImage = (message) => (
    <Cell>
        <StyledImage
            src={
                (message?.images && message?.images.length > 0 && message?.images[0].imageUrl) ||
                (message?.movies && message?.movies.length > 0 && message?.movies[0]?.image?.imageUrl)
            }
            alt={message?.texts?.find((val) => val.type === "primary_text")?.value}
        />
    </Cell>
);

// END CELL image + menu

// BEGIN CELL title
const StyledTitle = styled.div`
    font-size: 11px;
    line-height: 12px !important;
    display: inline-flex;
`;

const renderCellTitle = (message) => (
    <Cell wrapText>
        <StyledTitle>{message?.title}</StyledTitle>
    </Cell>
);
// END CELL Title

// BEGIN CELL perf
const StyledPerf = styled.div`
    height: 30px;
    width: 80px;
    display: flex;
    align-items: center;
`;

const getIntentForPerf = (pref) => {
    if (pref <= 0.25) {
        return Intent.DANGER;
    }
    if (pref <= 0.5) {
        return Intent.WARNING;
    }
    return Intent.SUCCESS;
};
const renderCellPerf = (message) => (
    <Cell>
        {message.stats.perfIndex ? (
            <Tooltip2 content={message.stats.perfIndex}>
                <StyledPerf>
                    <ProgressBar
                        value={message.stats.perfIndex}
                        animate={false}
                        stripes={false}
                        intent={getIntentForPerf(message.stats.perfIndex)}
                    />
                </StyledPerf>
            </Tooltip2>
        ) : (
            ""
        )}
    </Cell>
);

// END CELL perf

// BEGIN Cell lang
const StyledLang = styled.span`
    text-transform: uppercase;
`;
const renderCellLang = (message) => (
    <Cell>
        <StyledLang>{message.lang}</StyledLang>
    </Cell>
);
// END Cell lang

// BEGIN STATE CELL
const getIntentForState = (state) => {
    if (state === "live") {
        return Intent.SUCCESS;
    }
    if (state === "review") {
        return Intent.WARNING;
    }
    if (state === "completed") {
        return Intent.PRIMARY;
    }
    if (state === "completion") {
        return Intent.DANGER;
    }
    return null;
};
const renderCellStatus = (message) => (
    <Cell>
        <Tag minimal intent={getIntentForState(message.state)}>
            {message.state}
        </Tag>
    </Cell>
);

const getNameForCommercialState = (state) => {
    if (state === "not_in_commercial_pipe") {
        return "-";
    }
    if (state === "e_counter_customer") {
        return "E-Counter Customer";
    }
    if (state === "generating_draft") {
        return "Generating Draft";
    }
    if (state === "draft_ready") {
        return "Draft Ready";
    }
    if (state === "draft_error") {
        return "Draft gen. error";
    }
    if (state === "customer_contacted") {
        return "Customer Contacted";
    }
    if (state === "asks_premium_monthly") {
        return "🔥 asks MONTHLY";
    }
    if (state === "asks_premium_yearly") {
        return "🔥🔥 asks YEARLY";
    }
    if (state === "started_premium") {
        return "🏅 PREMIUM";
    }
    return null;
};
const renderCellCommercialStatus = (message) => (
    <Cell>
        <Tag minimal>{getNameForCommercialState(message.commercialState)}</Tag>
    </Cell>
);

// END STATE CELL

// BEGIN CELL owner
const renderCellOwner = (message) => (
    <Cell>
        <UserBadge user={message.owner} size={30} showEmail />
    </Cell>
);
// END Cell Owner

// BEGIN CELL platforms

const StyledCellPlatforms = styled.span`
    & > *:not(:last-child) {
        margin-right: 5px;
    }
`;
const renderCellPlatforms = (message) => (
    <Cell wrapText>
        <StyledCellPlatforms>
            {message.platformsInfo?.map((p) => (
                <PlatformInfoBadge
                    key={p.name}
                    platform={p}
                    currency={message.currency}
                    messageBusinessUuid={message.business?.uuid}
                />
            ))}
        </StyledCellPlatforms>
    </Cell>
);

//END CELL platforms

// BEGIN CELL budget

const StyledBudget = styled.div`
    min-width: 100px;
    small {
        font-size: 0.8em;
    }
    .bp4-divider {
        margin: 3px 0;
    }
    > .alert {
        color: ${Colors.RED2};
    }
`;

const renderCellBudget = (message) => {
    if (!message.stats.totalAmount) {
        return <Cell></Cell>;
    }
    return (
        <Cell>
            <Popover2
                content={
                    <PropertiesGrid
                        padding="5px"
                        values={[
                            {
                                label: "Total budget",
                                value: formatCurrency(message.stats.totalAmount, message.currency),
                            },
                            {
                                label: "Spent",
                                value: formatCurrency(message.stats.totalSpent, message.currency),
                            },
                            {
                                label: "Left",
                                value: formatCurrency(
                                    message.stats.totalAmount - message.stats.totalSpent,
                                    message.currency
                                ),
                                alert: message.stats.totalSpent > message.stats.totalAmount,
                            },
                            {
                                label: "% spent",
                                value: formatPercent(message.stats.totalSpent / message.stats.totalAmount),
                            },
                            {
                                label: "Last spent at",
                                value: message.stats.lastSpentAt
                                    ? formatDateString(message.stats.lastSpentAt, "short")
                                    : "never",
                            },
                        ]}
                    />
                }
            >
                <StyledBudget tabIndex={0}>
                    <strong className={message.stats.totalSpent > message.stats.totalAmount ? "alert" : ""}>
                        {formatCurrency(message.stats.totalSpent, message.currency)}
                    </strong>
                    <Divider />
                    <small>{formatCurrency(message.stats.totalAmount, message.currency)}</small>
                </StyledBudget>
            </Popover2>
        </Cell>
    );
};
// END CELL budget
const renderCellWallet = (message) => {
    return (
        <Cell>
            <Tag round intent={message?.initialAmount > message.availableWallet?.balance ? Intent.DANGER : Intent.NONE}>
                {formatCurrency(message.availableWallet?.balance, message.currency)}
            </Tag>
        </Cell>
    );
};
// BEGIN CELL topic
const renderCellTopic = (message) => {
    return <Cell>{message.topic}</Cell>;
};
// END CELL topic

// BEGIN CELL subscription
const StyledSubscriptionCell = styled.div`
    display: flex;
    flex-direction: column;
    min-width: 120px;
    text-align: center;
`;
const renderCellSubscription = (message) => {
    if (!message.subscription) {
        return <Cell></Cell>;
    }
    const interval = message.initialInterval;
    let amount = formatCurrency(message.subscription?.adAmount, message.currency);
    if (message.msgSubscription?.gateway === "amplify_wallet")
        amount = formatCurrency(message.subscription?.amount, message.currency);
    const startDate = message.subscription.startedAt;
    const nextDueDate = message.subscription.nextDueDate;

    return (
        <Cell>
            <Popover2
                content={
                    <PropertiesGrid
                        padding="5px"
                        values={[
                            {
                                label: "Subscription",
                                value: interval,
                            },
                            {
                                label: `Amount per ${interval.toLowerCase()}`,
                                value: amount,
                            },
                            {
                                label: `started at`,
                                value: startDate,
                            },
                            {
                                label: `next due at`,
                                value: nextDueDate,
                            },
                        ]}
                    />
                }
            >
                <StyledSubscriptionCell tabIndex={0}>
                    <strong>{interval}</strong>
                    <Divider />
                    <p>{amount}</p>
                </StyledSubscriptionCell>
            </Popover2>
        </Cell>
    );
};
// END CELL subscription

const renderCellSubType = (message) => {
    let subtype = message.msgSubscription?.gateway;
    if (!subtype) subtype = "Stripe";

    if (message.state === "live")
        return (
            <Cell style={{ display: "flex", justifyContent: "center" }}>
                <Tag minimal intent={subtype === "amplify_wallet" ? Intent.SUCCESS : Intent.DANGER}>
                    {subtype}
                </Tag>
            </Cell>
        );
    return <Cell></Cell>;
};

const renderCellLanding = (message) => {
    if (message?.landing)
        return (
            <Cell style={{ display: "flex", justifyContent: "center", gap: "5px" }}>
                <Button
                    intent={Intent.SUCCESS}
                    onClick={() => window.open(`${window.LANDING_SERVER_URL}/l/${message.landing.slug}`)}
                    icon="open-application"
                />
                <Button
                    outlined
                    intent={Intent.PRIMARY}
                    onClick={() => window.open(buildAppUrl(`/edit/landing/editor/${message.landing.slug}`))}
                    icon="edit"
                />
            </Cell>
        );
    return <Cell></Cell>;
};
const renderStepWizard = (message) => {
    let step = JSON.parse(message.wizardMetas)?.currentStep;
    if (step)
        return (
            <Cell style={{ display: "flex", justifyContent: "center" }}>
                <Tag minimal intent={step === "RecapStepWallet" ? Intent.SUCCESS : Intent.DANGER}>
                    {step}
                </Tag>
            </Cell>
        );
    return <Cell></Cell>;
};

const createRenderCellDate = (attribute) => (message) => {
    if (!message?.[attribute]) {
        return <Cell />;
    }
    return <Cell>{formatDateString(message[attribute], "short")}</Cell>;
};

const StyledMessagesTable = styled.div`
    width: 100%;
    padding: 10px;
    position: relative;
    .centered-table-cell,
    .bp4-table-cell {
        display: flex;
        align-items: center;
    }
`;

const MessagesTable = ({ messages, loading, ordering = {}, onChangeSort, hasPermission }) => {
    const { showDialog } = useContext(DialogContext);
    const history = useHistory();

    const withMessage =
        (func, ...args) =>
        (index) =>
            func(messages[index], ...args);
    const [columnWidths, setColumnWidths] = useState([
        50, 80, 80, 400, 60, 150, 100, 100, 160, 120, 120, 120, 100, 100, 100, 150, 140, 140, 100, 140,
    ]);

    const addMoneyFromWalletForMessage = useCallback(
        async (message) => {
            await showDialog(DialogAddMoneyFromWallet, { message });
        },
        [showDialog]
    );

    const restoreMoneyToWalletForMessage = useCallback(
        async (message) => {
            await showDialog(DialogRestoreMoneyToWallet, { message });
        },
        [showDialog]
    );

    const refundRemainingAmount = useCallback(
        async (message) => {
            await showDialog(DialogRefundRemainingAmount, { message });
        },
        [showDialog]
    );

    const deleteMessage = useCallback(
        async (message) => {
            await showDialog(DeleteMessage, { message });
        },
        [showDialog]
    );
    const createTicket = useCallback(
        async (message) => {
            await showDialog(CreateTicket, { message });
        },
        [showDialog]
    );

    return (
        <StyledMessagesTable>
            <Table2
                numRows={messages.length}
                enableRowHeader={false}
                numFrozenColumns={2}
                fill
                defaultRowHeight={55}
                columnWidths={columnWidths}
                onColumnWidthChanged={(idx, val) => {
                    const newWidths = [...columnWidths];
                    newWidths[idx] = val;
                    setColumnWidths(newWidths);
                }}
                loadingOptions={loading ? [TableLoadingOption.COLUMN_HEADERS, TableLoadingOption.CELLS] : []}
                renderMode={RenderMode.NONE} // https://github.com/palantir/blueprint/issues/4094#issuecomment-689190282
            >
                <Column
                    name=""
                    cellRenderer={withMessage(renderCellMenu, {
                        addMoneyFromWalletForMessage,
                        restoreMoneyToWalletForMessage,
                        refundRemainingAmount,
                        deleteMessage,
                        createTicket,
                        history,
                        hasPermission,
                    })}
                />
                <Column name="Agency" cellRenderer={withMessage(renderCellAgency)} />

                <Column
                    name=""
                    cellRenderer={withMessage(renderCellImage)}
                    nameRenderer={() => <Icon icon="media" color="gray" />}
                />
                <Column name="Title" cellRenderer={withMessage(renderCellTitle)} />
                <Column
                    name="Lang"
                    cellRenderer={withMessage(renderCellLang)}
                    columnHeaderCellRenderer={sortableColumnRenderer({
                        name: "Lang",
                        sortKey: "detected_lang",
                        ordering,
                        onChangeSort,
                    })}
                />
                <Column name="Owner" cellRenderer={withMessage(renderCellOwner)} />
                <Column
                    columnHeaderCellRenderer={sortableColumnRenderer({
                        icon: "chart",
                        sortKey: "perfIndex",
                        ordering,
                        onChangeSort,
                    })}
                    cellRenderer={withMessage(renderCellPerf)}
                />
                <Column
                    cellRenderer={withMessage(renderCellStatus)}
                    columnHeaderCellRenderer={sortableColumnRenderer({
                        name: "Status",
                        sortKey: "state",
                        ordering,
                        onChangeSort,
                    })}
                />
                <Column
                    cellRenderer={withMessage(renderCellCommercialStatus)}
                    columnHeaderCellRenderer={sortableColumnRenderer({
                        name: "Commercial status",
                        sortKey: "commercial_state",
                        ordering,
                        onChangeSort,
                    })}
                />
                <Column name="platforms" cellRenderer={withMessage(renderCellPlatforms)} />
                <Column
                    name="Budget"
                    cellRenderer={withMessage(renderCellBudget)}
                    columnHeaderCellRenderer={sortableColumnRenderer({
                        name: "Budget",
                        sortOptions: [
                            { label: "Last spent date", sortKey: "lastSpentAt" },
                            { label: "Total budget", sortKey: "totalBudget" },
                            { label: "Remaining budget", sortKey: "remainingBudget" },
                        ],
                        ordering,
                        onChangeSort,
                    })}
                />
                <Column
                    name="Wallet"
                    cellRenderer={withMessage(renderCellWallet)}
                    columnHeaderCellRenderer={sortableColumnRenderer({
                        icon: <Icon icon="bank-account" color="gray" />,
                        name: "Wallet",
                    })}
                />
                <Column
                    cellRenderer={withMessage(createRenderCellDate("createdDate"))}
                    columnHeaderCellRenderer={sortableColumnRenderer({
                        name: "Created",
                        sortKey: "insertedAt",
                        ordering,
                        onChangeSort,
                    })}
                />
                <Column
                    cellRenderer={withMessage(createRenderCellDate("startDate"))}
                    columnHeaderCellRenderer={sortableColumnRenderer({
                        name: "Started",
                        sortKey: "startedAt",
                        ordering,
                        onChangeSort,
                    })}
                />
                <Column
                    cellRenderer={withMessage(createRenderCellDate("liveEndDate"))}
                    columnHeaderCellRenderer={sortableColumnRenderer({
                        name: "End",
                        sortKey: "liveEndDate",
                        ordering,
                        onChangeSort,
                    })}
                />
                <Column
                    cellRenderer={withMessage(renderCellTopic)}
                    columnHeaderCellRenderer={sortableColumnRenderer({
                        name: "Topic",
                        sortKey: "topic",
                        ordering,
                        onChangeSort,
                    })}
                />
                <Column
                    cellRenderer={withMessage(renderCellSubscription)}
                    columnHeaderCellRenderer={sortableColumnRenderer({
                        icon: <Icon icon="stopwatch" color="gray" />,
                        name: "Subscription",
                        sortKey: "initialInterval",
                        ordering,
                        onChangeSort,
                    })}
                />
                <Column
                    cellRenderer={withMessage(renderCellSubType)}
                    columnHeaderCellRenderer={sortableColumnRenderer({
                        icon: <Icon icon="dollar" color="gray" />,
                        name: "Sub Type",
                        sortKey: "initialInterval",
                        ordering,
                        onChangeSort,
                    })}
                />
                <Column
                    cellRenderer={withMessage(renderCellLanding)}
                    columnHeaderCellRenderer={sortableColumnRenderer({
                        icon: <Icon icon="globe-network" color="gray" />,
                        name: "Landing",
                    })}
                />
                <Column
                    cellRenderer={withMessage(renderStepWizard)}
                    columnHeaderCellRenderer={sortableColumnRenderer({
                        icon: <Icon icon="numbered-list" color="gray" />,
                        name: " Step Wizard",
                        ordering,
                        onChangeSort,
                    })}
                />
            </Table2>
        </StyledMessagesTable>
    );
};

MessagesTable.propTypes = {
    messages: PropTypes.arrayOf(
        PropTypes.shape({
            title: PropTypes.string,
            msgSubscription: PropTypes.shape({
                gateway: PropTypes.string,
            }),
        })
    ).isRequired,
    loading: PropTypes.bool,
    ordering: PropTypes.shape({}),
    onChangeSort: PropTypes.func,
    hasPermission: PropTypes.func,
};

MessagesTable.defaultProps = {
    loading: false,
    ordering: {},
    onChangeSort: null,
    hasPermission: null,
};

export default MessagesTable;
