import { Button, Dialog, Intent, Tag } from "@blueprintjs/core";
import PropTypes from "prop-types";
import React, { useState } from "react";
import styled from "styled-components";

import FileButton from "../../common/bp-file-button";
import SimpleSelect from "../../common/simple-select";
import { useMutationCreateImage, useUploadAsset } from "../../gql-assets";
import { StyledPropertiesAsset } from "./field-text";

export const StyledImage = styled.img`
    width: 300px;
    height: 200px;
    object-fit: cover;
    object-position: center;
    background: repeating-linear-gradient(
        45deg,
        rgba(0, 0, 0, 0.2),
        rgba(0, 0, 0, 0.2) 10px,
        rgba(0, 0, 0, 0.3) 10px,
        rgba(0, 0, 0, 0.3) 20px
    );
    border-radius: 5px;
    overflow: hidden;
`;
const FullSizeImage = styled.img`
    max-width: 100%;
    max-height: 80vh;
    object-fit: contain;
`;

const StyledFieldImage = styled.div`
    border-radius: 3px;
    background-color: ${({ theme, $disabled }) => ($disabled ? theme.lightGrayColor : "white")};
    border: 1px solid ${({ theme }) => theme.grayColor};
    padding: 5px;
    & > .actions {
        padding-top: 5px;
    }
`;

const image_roles = [
    {
        value: "default",
        label: "Default",
    },
    {
        value: "video_thumbnail",
        label: "Video Thumbnail",
    },
    {
        value: "logo",
        label: "Logo",
    },
    {
        value: "landscape_logo",
        label: "Landscape Logo",
    },
];
const image_types = [
    {
        value: "image",
        label: "Image",
    },
];

const FieldImage = ({ index, value, disabled, onChange, allPlatforms, platform = null }) => {
    const [progress, setProgress] = useState(null);
    const [uploadAsset] = useUploadAsset();
    const [createImage] = useMutationCreateImage();
    const [isDialogOpen, setIsDialogOpen] = useState(false);

    const handleImageClick = () => setIsDialogOpen(true);
    const handleCloseDialog = () => setIsDialogOpen(false);
    const handleSetImage = async (file) => {
        try {
            setProgress(0);
            const assetUrl = await uploadAsset(file, setProgress);
            const createdImageResult = await createImage({
                title: file.name,
                url: assetUrl,
            });
            const updatedData = { ...createdImageResult.data.createImage.image };

            if (platform !== null) {
                updatedData.platform = platform;
            }

            onChange(updatedData);
        } finally {
            setProgress(null);
        }
    };
    if (!value) {
        return (
            <StyledFieldImage>
                <p>
                    <b>NEW IMAGE</b>
                </p>
                <StyledImage style={{ width: "100px", height: "100px" }} src={null} />
                <div className="actions">
                    {!disabled && (
                        <FileButton
                            inputId={"field-image-set-image-" + index}
                            text="Set image..."
                            disabled={disabled}
                            onFileUpload={handleSetImage}
                            progress={progress}
                            icon="media"
                            rightIcon="folder-open"
                            title="Select an image an upload it"
                        />
                    )}
                </div>
            </StyledFieldImage>
        );
    }
    return (
        (!value?.platform || value?.platform === platform) && (
            <StyledFieldImage>
                <p>
                    <b>IMAGE {index}</b>
                </p>
                <StyledImage src={value.imageUrl} onClick={handleImageClick} />
                <Dialog
                    isOpen={isDialogOpen}
                    onClose={handleCloseDialog}
                    title={`Image ${index}`}
                    canOutsideClickClose={true}
                    canEscapeKeyClose={true}
                >
                    <div className="bp4-dialog-body">
                        <FullSizeImage src={value.imageUrl} alt={`Full Image ${index}`} />
                    </div>
                    <div className="bp4-dialog-footer">
                        <Button intent="primary" onClick={handleCloseDialog}>
                            Close
                        </Button>
                    </div>
                </Dialog>
                <div className="actions">
                    {!disabled && (
                        <FileButton
                            inputId={"field-image-set-image-" + index}
                            text="Change image..."
                            disabled={disabled}
                            onFileUpload={handleSetImage}
                            progress={progress}
                            icon="folder-open"
                            title="Select an image an upload it"
                        />
                    )}
                    <StyledPropertiesAsset>
                        {!disabled && allPlatforms && (
                            <div>
                                <Tag minimal active intent={Intent.DANGER}>
                                    Platform :{" "}
                                </Tag>
                                <SimpleSelect
                                    disabled={disabled}
                                    options={allPlatforms}
                                    onSelect={(v) => onChange({ ...value, platform: v })}
                                    selected={value.platform}
                                />
                            </div>
                        )}
                        {!disabled && (
                            <div>
                                <Tag minimal active intent={Intent.SUCCESS}>
                                    Type :{" "}
                                </Tag>
                                <SimpleSelect
                                    disabled={disabled}
                                    options={image_types}
                                    onSelect={(v) => onChange({ ...value, type: v })}
                                    selected={value.type}
                                />
                            </div>
                        )}
                        {!disabled && (
                            <div>
                                <Tag minimal active intent={Intent.PRIMARY}>
                                    Role :{" "}
                                </Tag>
                                <SimpleSelect
                                    disabled={disabled}
                                    options={image_roles}
                                    onSelect={(v) => onChange({ ...value, role: v })}
                                    selected={value.role}
                                />
                            </div>
                        )}
                    </StyledPropertiesAsset>
                </div>
            </StyledFieldImage>
        )
    );
};

FieldImage.propTypes = {
    index: PropTypes.number.isRequired,
    value: PropTypes.shape({
        imageUrl: PropTypes.string.isRequired,
        type: PropTypes.string.isRequired,
        role: PropTypes.string,
        platform: PropTypes.string,
    }),
    onChange: PropTypes.func.isRequired,
    onRemove: PropTypes.func.isRequired,
    disabled: PropTypes.bool,
    allPlatforms: PropTypes.arrayOf(PropTypes.shape({})),
    platform: PropTypes.string,
};

FieldImage.defaultProps = {
    value: null,
    disabled: false,
    allPlatforms: [],
    platform: null,
};

export default FieldImage;
