import { Button, Callout, Classes, Intent } from "@blueprintjs/core";
import PropTypes from "prop-types";
import React, { useCallback, useState } from "react";

import { checkMessageAssetsPromise } from "../messges/gql-messages";

const CheckAssetsDialog = ({ hideDialog, isDialogProcessing, message }) => {
    const [errors, setErrors] = useState([]);
    const [isMessageValid, setIsMessageValid] = useState(false);

    const handleCheckMessage = useCallback(async () => {
        const result = await checkMessageAssetsPromise({
            id: message.id,
        });
        setErrors(result.errors);
        setIsMessageValid(result.valid);
        // console.log(rest)
    }, [message.id]);
    return (
        <>
            <div className={Classes.DIALOG_BODY}>
                {isMessageValid ? <p>All your Assets ar valid</p> : "Checking assets"}
            </div>
            <div className={Classes.DIALOG_BODY}>
                {errors.map((error) => {
                    return <Callout icon="error" intent={Intent.DANGER} title={error}></Callout>;
                })}
            </div>

            <div className={Classes.DIALOG_FOOTER}>
                <div className={Classes.DIALOG_FOOTER_ACTIONS}>
                    <Button disabled={isDialogProcessing} text="Cancel" onClick={() => hideDialog(0)} />
                    {isMessageValid ? (
                        <Button text="Ok" intent={Intent.SUCCESS} onClick={() => hideDialog(0)} />
                    ) : (
                        <Button
                            loading={isDialogProcessing}
                            intent={Intent.WARNING}
                            text="Check Message"
                            onClick={handleCheckMessage}
                            rightIcon="automatic-updates"
                        />
                    )}
                </div>
            </div>
        </>
    );
};

CheckAssetsDialog.propTypes = {
    hideDialog: PropTypes.func.isRequired,
    setIsDialogProcessing: PropTypes.func.isRequired,
    isDialogProcessing: PropTypes.bool.isRequired,
    message: PropTypes.shape({
        id: PropTypes.string.isRequired,
        state: PropTypes.string.isRequired,
        title: PropTypes.string.isRequired,
        texts: PropTypes.arrayOf(
            PropTypes.shape({
                value: PropTypes.string,
                type: PropTypes.string,
            })
        ),
    }).isRequired,
};

export default {
    title: "Check Assets",
    icon: "send-message",
    component: CheckAssetsDialog,
};
