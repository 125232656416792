import { Intent } from "@blueprintjs/core";
import React, { useCallback, useContext, useMemo, useState } from "react";
import { RiAdvertisementLine } from "react-icons/ri";
import { useHistory, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import styled from "styled-components";

import { getRootUserAgencyPromise } from "../../../common/gql-promises";
import { peek } from "../../../common/utils";
import { DialogContext } from "../../bp-dialog-context";
import ErrorCallout from "../../common/error-callout";
import LoadingCallout from "../../common/loading-callout";
import NavBreadcrumb from "../../common/nav-breadcrumb";
import { buildQuestionDialog } from "../../dialogs/helpers";
import { useApproveChanges } from "../../validation/gql-validation";
import { useMutationUpdateMessage, useQueryLandings, useQueryMessage } from "../gql-messages";
import NavigationMenu from "../message-menu";
import MessageForm from "./message-form";

const StyledMessageEditPage = styled.div`
    background-color: ${({ theme }) => theme.editorBackgroundColor};
`;

const removeTypename = (data) => {
    const omitTypename = (key, value) => (key === "__typename" ? undefined : value);
    return JSON.parse(JSON.stringify(data), omitTypename);
};

const MessageEditPage = () => {
    const { id } = useParams();
    const { message, loading, loadError, refetch } = useQueryMessage(id);
    const { landings } = useQueryLandings(0, 10, message?.owner?.email);
    const [saveMessage, { loading: saving, error: saveError }] = useMutationUpdateMessage();
    const [disablePrompt, setDisablePrompt] = useState(false);
    const enhancedMessage = useMemo(() => {
        return message
            ? { ...message, targetingAge: { min: message.targetingMinAge, max: message.targetingMaxAge } }
            : null;
    }, [message]);
    const approveChanges = useApproveChanges();
    const history = useHistory();
    const { showDialog } = useContext(DialogContext);
    const onChangeMessage = useCallback(
        async (newMessageData, close) => {
            // sanitize message data, peek only change-able key and remove __type
            const changes = peek(newMessageData, [
                "topic",
                [
                    "texts",
                    "texts",
                    (txts) => (txts ? txts.map((x) => peek(x, ["id", "value", "type", "role", "platform"])) : []),
                ],
                [
                    "images",
                    "images",
                    (images) => (images ? images.map((x) => peek(x, ["id", "type", "role", "platform"])) : []),
                ],
                [
                    "movies",
                    "movies",
                    (movies) =>
                        movies
                            ? movies.map((movie) => {
                                  return {
                                      movieId: movie.id,
                                      movieImageId: movie?.image?.id,
                                      type: movie?.type,
                                      role: movie?.role,
                                      platform: movie?.platform,
                                  };
                              })
                            : undefined,
                ],
                ["owner", "ownerId", (owner) => owner?.id || undefined],
                ["accountManager", "accountManagerId", (accountManager) => accountManager?.id || undefined],
                "currency",
                "overview",
                "topic",
                "title",
                "initialAmount",
                "targetingGender",
                [
                    "platformsMessage",
                    "platformsMessage",
                    (psm) =>
                        psm.map((x) =>
                            peek(x, ["platform", "active", "format", "isDynamic", "budgetAllocationFactor"])
                        ),
                ],
                "enforceAdLanguage",
                "liveEndDate",
                "adLink",
                "utm",
                ["targetingLinks", "targetingList", (links) => (links ? links.map((l) => l.id) : [])],
                ["interestsLinks", "interestsList", (links) => (links ? links.map((l) => l.id) : [])],
                ["targetingProfile", "targetingProfileId", (profile) => profile?.id || null],
                ["targetingAge", "targetingMinAge", (ta) => ta.min],
                ["targetingAge", "targetingMaxAge", (ta) => ta.max],
                "tags",
                "landing",
                "targetingAllWorld",
            ]);

            const saveResult = await saveMessage({ id, changes: removeTypename(changes) });
            const lastReview = saveResult?.data?.updateMessage.message.lastReview;
            toast.success("Message saved successfully");
            const rootUser = await getRootUserAgencyPromise();
            if (lastReview && !lastReview.isNew && !rootUser) {
                // check if we have changes to propose a auto-approval of the message
                const { code } = await showDialog(
                    buildQuestionDialog({
                        title: "Review message",
                        body: `Do you want to accept the review for this message?`,
                        hideCancel: true,
                        actions: [
                            {
                                text: close ? "Close" : "Continue editing",
                                code: 0,
                                icon: close ? "small-cross" : "edit",
                                intent: Intent.NONE,
                            },
                            {
                                text: "Yes APPROVE review and close",
                                code: 1,
                                icon: "small-tick",
                                intent: Intent.SUCCESS,
                            },
                        ],
                    })
                );
                if (code === 1) {
                    await approveChanges({ id: lastReview.id });
                    toast.success("Message approved successfully");
                }
            }
            if (close) {
                setDisablePrompt(true);
                setTimeout(() => {
                    history.push("/messages/");
                }, 1);
            } else {
                await refetch();
            }
        },
        [refetch, id, saveMessage, history, approveChanges, showDialog]
    );

    return enhancedMessage ? (
        <StyledMessageEditPage>
            <MessageForm
                header={
                    <div>
                        <NavBreadcrumb
                            items={[
                                { icon: "home", to: "/" },
                                { text: "Messages", icon: <RiAdvertisementLine />, to: "/messages" },
                                enhancedMessage && {
                                    text: enhancedMessage?.id,
                                },
                                enhancedMessage && {
                                    text: "Edit",
                                    current: true,
                                },
                                loading && {
                                    icon: "",
                                    text: "loading...",
                                },
                            ]}
                            currentBreadcrumbRenderer={({ text, icon }) => (
                                <NavigationMenu message={message} text={text} icon={icon} />
                            )}
                        />
                        <ErrorCallout title="Error while loading" error={loadError} />
                        <ErrorCallout title="Error while saving" error={saveError} />
                        <LoadingCallout title="Loading..." loading={loading} />
                        <LoadingCallout title="Saving..." loading={saving} />
                    </div>
                }
                message={enhancedMessage}
                landings={landings}
                onChangeMessage={onChangeMessage}
                loading={loading || saving}
                disablePrompt={disablePrompt}
            />
        </StyledMessageEditPage>
    ) : null;
};

export default MessageEditPage;
